import clsx from "clsx"

import createStyle from "lib/createStyle"

export const Label = createStyle(
  "label",
  clsx("text-[0.875rem] font-medium tracking-[0.01em]")
)

export const Description = createStyle(
  "span",
  clsx("text-[0.875rem] tracking-[0.01em] text-medgray")
)

export const InputEl = createStyle(
  "input",
  clsx(
    "peer block",
    "p-4 w-full",
    "bg-white rounded-md",
    "text-offblack",
    "text-[1rem] leading-[1.25] tracking-[0.01em]",
    "border border-transparent",
    "focus:border-pea focus:outline-none",
    "placeholder-offblack placeholder-opacity-50",
    "disabled:placeholder-disabled disabled:text-disabled",
    "appearance-none text-left",
    "focus:invalid:border-red"
  )
)

export const RadioEl = createStyle(
  "input",
  clsx(
    "peer block appearance-none",
    "w-4 h-4",
    "border border-black border-opacity-50 bg-white",
    "rounded-full",
    "transition duration-200",
    "checked:bg-gradient-radio checked:from-white checked:to-gold checked:border-transparent",
    "disabled:bg-disabled"
  )
)

export const RadioCheck = createStyle(
  "input",
  clsx(
    "peer block appearance-none",
    "w-6 h-6",
    "border border-black border-opacity-50 bg-transparent",
    "rounded-md",
    "transition duration-200",
    "checked:bg-gold",
    "disabled:bg-disabled"
  )
)

export const CheckboxEl = createStyle(
  "input",
  clsx(
    "block peer appearance-none",
    "w-6 h-6",
    "rounded-md",
    "transition duration-200",
    "disabled:bg-disabled disabled:border-disabled"
  )
)
