import CloseIcon from "@/icons/Close"
import { SerifH1 } from "@/styles/Type"
import * as Dialog from "@radix-ui/react-dialog"
import React from "react"
import { useDraggable } from "@josephmark/hooks"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import Image from "next/image"

const LookBookModalEdit = ({ look_book }) => {
  const ref = useDraggable()

  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed z-40 inset-0 flex items-center justify-center bg-black bg-opacity-60">
        <Dialog.Content className="relative bg-offblack text-white py-7 px-4 lg:px-7 flex flex-col w-0 flex-grow">
          <div className="mb-5 flex pl-10 pt-5">
            <Dialog.Title asChild>
              <SerifH1 className="flex-grow">Lookbook</SerifH1>
            </Dialog.Title>
            <Dialog.Close className="w-9 h-9 rounded-full border border-white flex items-center justify-center flex-shrink-0 absolute top-5 right-5">
              <CloseIcon width="16" height="16" />
            </Dialog.Close>
          </div>
          <div
            className="flex items-start overflow-auto scrollbar-xs flex-grow gap-4 lg:gap-14 -mx-4"
            ref={ref}
          >
            <div className="w-px flex-shrink-0" />
            {look_book.map((item, idx) => (
              <div key={idx} className="flex flex-col items-center">
                <div style={{ width: "600px", height: "460px" }}>
                  <Image
                    src={item.image_src}
                    alt={item.alt_text || ""}
                    width="600"
                    height="380"
                    className="object-contain"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
                <span>{item.alt_text}</span>
                <span>{item.description}</span>
              </div>
            ))}
            <div className="w-px flex-shrink-0" />
          </div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  )
}

const ImagePreviewSlider = ({ look_book }) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger className="w-full block">
        <div className="w-[60px] h-[30px] flex justify-center items-center border border-solid border-black">
          <button
            type="button"
            className="flex items-center justify-center w-[100px]"
          >
            View
          </button>
        </div>
      </Dialog.Trigger>
      <Dialog.Overlay className="fixed inset-0 z-40 bg-black bg-opacity-60 lg:p-8 flex">
        <LookBookModalEdit look_book={look_book} />
      </Dialog.Overlay>
    </Dialog.Root>
  )
}

export default ImagePreviewSlider
