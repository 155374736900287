import {
  forwardRef,
  Fragment,
  HTMLAttributes,
  useCallback,
  useId,
  useMemo,
  useState,
} from "react"
import ErrorHandler from "@/icons/ErrorHandler"
import clsx from "clsx"
import useForm from "@/lib/useForm"
import useAuth from "@/hooks/useAuth"
import { Description, InputEl, Label } from "./styles"
import LookBookModalEdit from "../ImagePreviewSlider"
import type { InputProps } from "./types"

export const Input = forwardRef<HTMLElement, InputProps>(
  (
    {
      label,
      name,
      children,
      description = "",
      viewButton,
      className,
      wrapper,
      min: _min,
      max: _max,
      metadata,
      required,
      onBlur,
      customStyles,
      maxLength,
      ...props
    },
    ref
  ) => {
    const { form } = useForm()
    const { masquerade } = useAuth()
    const hash = useId()
    const id =
      props?.as === "div"
        ? undefined
        : props.id || [name, "input", hash].join("-")

    const Wrapper = wrapper ?? Fragment
    const wrapperProps: HTMLAttributes<any> = {}
    const [isFieldValid, setIsFieldValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [isFocused, setIsFocused] = useState(false)

    const validateField = useCallback(
      (input) => {
        if (input.type !== "number" && _min > 0) {
          if (parseFloat(input.value) < _min) {
            setErrorMessage("The input is invalid.")
            setIsFieldValid(false)
            input.setCustomValidity("The input is invalid.")
            return
          }
          input.setCustomValidity("")
        }
        if (
          typeof input?.checkValidity === "function" &&
          !input?.checkValidity?.()
        ) {
          if (input.validity.valueMissing) {
            setErrorMessage("Please fill out this field.")
          } else if (input.validity.typeMismatch) {
            setErrorMessage("Please enter the correct type.")
          } else {
            setErrorMessage("The input is invalid.")
          }
          setIsFieldValid(false)
        } else {
          setErrorMessage("")
          setIsFieldValid(true)
        }
      },
      [_min]
    )

    const onInputChange = useCallback(
      (e) => {
        if (maxLength && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength)
        }

        validateField(e.target)

        if (masquerade?.status !== "Draft" && props.onChange) {
          props.onChange(e)
        }
      },
      [masquerade?.status, props, validateField, maxLength]
    )

    const onInputFocus = useCallback(
      (e) => {
        setTimeout(() => {
          validateField(e.target)
          setIsFocused(true)
        }, 100)
      },
      [validateField]
    )

    const onInputBlur = useCallback(
      (e) => {
        setTimeout(() => {
          setIsFocused(false)
          validateField(e.target)
          if (onBlur) {
            onBlur(e)
          }
        }, 100)
      },
      [onBlur, validateField]
    )

    const min = useMemo(() => {
      if (typeof _min === "number") {
        return _min
      }
      return form?.[name]?.min ?? _min
    }, [_min, form, name])

    if (wrapper) {
      wrapperProps.className = className
      wrapperProps.style = props.style
    }

    const labelStyles = useMemo(() => {
      if (customStyles) {
        return customStyles
      }
      if (metadata?.onboarding && window.location.href.includes("welcome")) {
        return {
          fontSize: "30px",
          fontWeight: 500,
          fontStyle: "normal",
          lineHeight: "120%",
          letterSpacing: "-0.3px",
          marginBottom: "40px",
        }
      }
      return undefined
    }, [customStyles, metadata?.onboarding])

    const inputStyles = useMemo(() => {
      if (metadata?.onboarding && window.location.href.includes("welcome")) {
        return {
          width: "60%",
        }
      }
      return undefined
    }, [metadata?.onboarding])

    const charCount = useMemo(() => {
      if (typeof props.value === "string") {
        return props.value.length
      }
      return 0
    }, [props.value])

    return (
      <div className="relative">
        {(label || description || viewButton) && (
          <label className="block group " htmlFor={id as string}>
            <div className="gap-[4px] items-center mb-[8px] leading-[20px]">
              {isFieldValid ? (
                <Label
                  as="span"
                  className="mr-1  tracking-normal text-gunmetal"
                  style={labelStyles}
                >
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                  {required && " *"}
                </Label>
              ) : (
                <Label
                  as="span"
                  className="!font-normal text-red flex tracking-normal items-center gap-[2.5px]"
                  style={labelStyles}
                >
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                  {required && <span> *</span>}{" "}
                  {required && <ErrorHandler className="mr-1" />}{" "}
                </Label>
              )}
              <div className="flex justify-between">
                <div>
                  {description && (
                    <Description className={metadata?.description_css}>
                      {description.split("\n").map((s, i) =>
                        i === 0 ? (
                          s
                        ) : (
                          <span key={i} className="max-w-[450px]">
                            <br />
                            {s}
                          </span>
                        )
                      )}
                    </Description>
                  )}
                </div>
                <div>
                  {viewButton && (
                    <LookBookModalEdit look_book={form.look_book} />
                  )}
                </div>
              </div>
            </div>
          </label>
        )}
        <div className="relative">
          <Wrapper {...wrapperProps}>
            <>
              {children ? (
                <InputEl
                  style={{
                    border: isFocused
                      ? "1px solid #000"
                      : "1px solid #27272720",
                    ...inputStyles,
                  }}
                  id={id}
                  name={name}
                  ref={ref}
                  className={clsx(!wrapper && className)}
                  {...props}
                  min={min}
                  max={_max}
                  required={masquerade?.status !== "Draft" && required}
                  onInput={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                >
                  {children}
                </InputEl>
              ) : (
                <InputEl
                  style={{
                    border: isFocused
                      ? "1px solid #000"
                      : "1px solid #27272720",
                    ...inputStyles,
                  }}
                  id={id}
                  name={name}
                  ref={ref}
                  className={clsx(!wrapper && className)}
                  {...props}
                  min={min}
                  max={_max}
                  required={masquerade?.status !== "Draft" && required}
                  onInput={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                />
              )}
              {maxLength && (
                <span className="absolute bottom-0 right-3 text-gray-400 text-xs">
                  {charCount}/{maxLength}
                </span>
              )}
            </>
          </Wrapper>
        </div>
        <Label
          as="span"
          className="items-center text-red mt[-25px] "
          style={labelStyles}
        >
          {!isFieldValid && (
            <span className="text-xs text-red-500">{errorMessage}</span>
          )}
        </Label>
      </div>
    )
  }
)

export default Input
