import { SVGProps } from "react"

export const AngryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 4.83333V7.5M7 10.1667H7.00667M4.2 13.5H9.8C10.9201 13.5 11.4802 13.5 11.908 13.282C12.2843 13.0903 12.5903 12.7843 12.782 12.408C13 11.9802 13 11.4201 13 10.3V4.7C13 3.5799 13 3.01984 12.782 2.59202C12.5903 2.21569 12.2843 1.90973 11.908 1.71799C11.4802 1.5 10.9201 1.5 9.8 1.5H4.2C3.0799 1.5 2.51984 1.5 2.09202 1.71799C1.71569 1.90973 1.40973 2.21569 1.21799 2.59202C1 3.01984 1 3.5799 1 4.7V10.3C1 11.4201 1 11.9802 1.21799 12.408C1.40973 12.7843 1.71569 13.0903 2.09202 13.282C2.51984 13.5 3.0799 13.5 4.2 13.5Z"
      stroke="#BA0505"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default AngryIcon
